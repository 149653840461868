import React, { useState, useEffect } from 'react';
import './App.css';
import { ethers } from 'ethers';
import contractABI from './contractabi.json';

const contractAddress = '0x0b40390fF4d0F6C70623d79fFed98C7Bbf54e5E4';

function App() {
  const [currentAccount, setCurrentAccount] = useState("");
  const [userDeposit, setUserDeposit] = useState('');
  const [totalDeposits, setTotalDeposits] = useState('');
  const [winnableYield, setWinnableYield] = useState('');
  const [winningOdds, setWinningOdds] = useState('');
  const [depositAmount, setDepositAmount] = useState('');

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  async function checkIfWalletIsConnected() {
    const { ethereum } = window;
    if (!ethereum) {
      console.log('Ethereum object not found, please install MetaMask.');
      return;
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_accounts' });
      if (accounts.length !== 0) {
        const account = accounts[0];
        setCurrentAccount(account);
        updateUI();
      } else {
        console.log('No authorized account found');
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function connectWalletHandler() {
    const { ethereum } = window;
    if (!ethereum) {
      alert('Please install MetaMask to use this app.');
      return;
    }

    try {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      setCurrentAccount(accounts[0]);
      updateUI();
    } catch (error) {
      console.error('Error connecting to MetaMask:', error);
    }
  }

  async function deposit() {
    if (!currentAccount) {
        alert('Please connect your wallet first!');
        return;
    }

    try {
        const provider = new ethers.BrowserProvider(window.ethereum);
        const signer = await provider.getSigner();
        const contract = new ethers.Contract(contractAddress, contractABI, signer);

        const amount = ethers.parseEther(depositAmount); // Convert ETH to Wei
        const tx = await contract.deposit({ value: amount });
        console.log('Sending deposit...', tx.hash);

        await tx.wait();
        console.log('Deposit successful:', tx.hash);

        updateUI(); // Update the UI after deposit
    } catch (error) {
        console.error('Deposit failed:', error);
    }
}

  async function updateUI() {
    if (!currentAccount) return;

    const provider = new ethers.BrowserProvider(window.ethereum);
    const signer = await provider.getSigner();
    const contract = new ethers.Contract(contractAddress, contractABI, signer);

    try {
      const deposit = await contract.deposits(signer.getAddress());
      setUserDeposit(ethers.formatEther(deposit));

      const total = await contract.totalDeposits();
      setTotalDeposits(ethers.formatEther(total));

      const yieldw = await contract.winnableYield();
      setWinnableYield(ethers.formatEther(yieldw));

      const odds = await contract.addressWinningOdds(signer.getAddress());
      setWinningOdds(`${odds} %`);
    } catch (error) {
      console.error('Error updating UI:', error);
    }
  }

  return (
    <div className="mainContainer">
      <h1>Blast Together</h1>
      <div className="dataContainer">
        <button className="button" onClick={connectWalletHandler}>
          {currentAccount ? 'Wallet Connected' : 'Connect Wallet'}
        </button>

        <input 
          className="input"
          type="text" 
          value={depositAmount} 
          onChange={(e) => setDepositAmount(e.target.value)} 
          placeholder="Amount in ETH" 
        />
        <button className="button" onClick={deposit}>Deposit</button>

        <div>Your Deposit: {userDeposit} ETH</div>
        <div>Total Deposits: {totalDeposits} ETH</div>
        <div>Total Winnable Yield: {winnableYield} ETH</div>
        <div>Your Odds of Winning: {winningOdds}</div>
      </div>
    </div>
  );
}

export default App;